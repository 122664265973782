import { createTheme, Theme } from "@mui/material/styles";
import { THEME_COLORS } from "./themeColors";
import { SystemStyleObject } from '@mui/system';

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    h10: React.CSSProperties;
    h11: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h10?: React.CSSProperties;
    h11?: React.CSSProperties;
  }

  interface Components {
    MuiDataGrid?: {
      styleOverrides?: {
        root?: SystemStyleObject;
        cell?: SystemStyleObject;
        columnHeaders?: SystemStyleObject;
        columnHeader?: SystemStyleObject;
        "columnHeader--sorted"?: SystemStyleObject;
        columnHeaderTitle?: SystemStyleObject;
        columnSeparator?: SystemStyleObject;
        footerContainer?: SystemStyleObject;
        row?: SystemStyleObject;
      };
    };
    MuiCalendarPicker?: {
      styleOverrides?: {
        root?: SystemStyleObject;
      };
    };
    MuiPickersCalendarHeader?: {
      styleOverrides?: {
        root?: SystemStyleObject;
        labelContainer?: SystemStyleObject;
      };
    };
    MuiBox?: {
      styleOverrides?: {
        root?: SystemStyleObject;
      };
    };
  }

  interface Theme {
    subscription: {
      subscribed: {
        color: string;
        backgroundColor: string;
      };
      default: {
        color: string;
        backgroundColor: string;
      };
      oneTimePurchase: {
        color: string;
        backgroundColor: string;
      };
    };
  }

  interface ThemeOptions {
    subscription?: {
      subscribed?: {
        color: string;
        backgroundColor: string;
      };
      default?: {
        color: string;
        backgroundColor: string;
      };
      oneTimePurchase?: {
        color: string;
        backgroundColor: string;
      };
    };
  }

  interface SimplePaletteColorOptions {
    stackBackground?: string;
  }
}

declare module "@mui/material/Stack" {
  interface StackPropsVariantOverrides {
    primary: true;
    secondary: true;
  }

  interface StackOwnProps {
    variant?: 'primary' | 'warning' | 'error' | 'info' | 'success';
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    'leg1-holder': true;
    'leg2-holder': true;
    'member': true;
    'suspended': true;
    'inactive': true;
    'avatar': true;
    'Fulfilled': true;
    'Cancelled': true;
    'Canceled': true;
    'ReadyToProcess': true;
    'Processing': true;
    'Unfulfilled': true;
    'Hold': true;
    'Delivered': true;
    'Backordered': true;
    'Returned': true;
    'subscribed': true;
    'one_time_purchase': true;
    'rank-0': true;
    'rank-1': true;
    'rank-2': true;
    'rank-3': true;
    'rank-4': true;
    'rank-5': true;
    'rank-6': true;
    'rank-7': true;
    'rank-8': true;
    'rank-9': true;
    'rank-10': true;
    'rank-11': true;
    'rank-12': true;
    'ov-to-next-rank-chip': true;
    'ov-to-next-rank-value': true;
    'type-member': true;
    'type-retail': true;
    'type-wholesale': true;
    'type-preferred': true;
    'subscription-chip-subscribed': true;
    'subscription-chip-canceled': true;
    'subscription-chip-default': true;
    'subscription-chip-one-time-purchase': true;
    'sponsor-enroller-chip': true;
  }
}

const createDefaultTheme = (): Theme =>
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: THEME_COLORS.PRIMARY_MAIN,
        stackBackground: THEME_COLORS.LIGHT.STACK_BACKGROUND,
      },
      text: {
        primary: THEME_COLORS.LIGHT.TEXT_PRIMARY,
      },
      background: {
        default: THEME_COLORS.LIGHT.BACKGROUND_DEFAULT,
        paper: THEME_COLORS.LIGHT.BACKGROUND_PAPER,
      },
      severity: {
        error: THEME_COLORS.LIGHT.SEVERITY.ERROR,
        warning: THEME_COLORS.LIGHT.SEVERITY.WARNING,
        info: THEME_COLORS.LIGHT.SEVERITY.INFO,
        success: THEME_COLORS.LIGHT.SEVERITY.SUCCESS,
        default: THEME_COLORS.LIGHT.SEVERITY.DEFAULT
      },
    },
    typography: {
      fontFamily: "Inter",
      button: {
        textTransform: "none",
      },
      h2: {
        fontWeight: "600",
        fontSize: "16px"
      },
      h3: {
        fontWeight: "600",
        fontSize: "14px"
      },
      h4: {
        fontWeight: "600",
        fontSize: "12px"
      },
      h5: {
        fontWeight: "400",
        fontSize: "12px"
      },
      h6: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".6"
      },
      h7: {
        fontWeight: "400",
        fontSize: "12px",
        opacity: ".6"
      },
      h8: {
        fontWeight: "400",
        fontSize: "14px"
      },
      h9: {
        fontWeight: "500",
        fontSize: "11px"
      },
      h10: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".5"
      },
      h11: {
        fontWeight: "400",
        fontSize: "10px",
        color: "#919EAB"
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "body": {
            minHeight: "100vh",
            backgroundColor: THEME_COLORS.LIGHT.BACKGROUND_DEFAULT,
          },
          "html": {
            backgroundColor: THEME_COLORS.LIGHT.BACKGROUND_DEFAULT,
          },
          ".MuiListItem-root, .MuiMenuItem-root": {
            "& img[src$='.svg']": {
              opacity: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
          "&img[src$='.svg'].svg-boost": {
            opacity: "1"
          },
          "&.unicity-office-logo": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            "& .unicity-office-logo-text": {
              color: "rgb(4, 158, 207)",
              fontSize: "1.25em",
              fontWeight: 450,
            },
          },
          "&.search-popper": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            border: "1px solid rgba(145, 158, 171, 0.10)",
            filter: "drop-shadow(0px 1px 3px rgba(145, 158, 171, 0.10)) drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.08))",
            borderRadius: "8px",
          },
          "& svg": {
            "&.help-icon": {
              width: "18px",
              height: "18px",
            },
            "&.search-glass-icon, &.arrow-right-icon, &.arrow-left-icon, &.help-icon, &.notice-icon, &.phone-icon, &.message-icon, &.mail-icon, &.phone-disabled-icon, &.message-disabled-icon, &.mail-disabled-icon, &.mail-disabled-icon, &.message-disabled-icon, &.alert-close-icon, &.upgrade-icon, &.library-icon, &.download-icon, &.download-disabled-icon, &.content-copy-icon, &.close-icon, &.menu-icon, &.add-icon, &.external-icon, &.downline-icon, &.info-icon, &.auto-renew-icon, &.subscription-grey-icon, &.chevron-right-icon, &.genealogy-icon": {
              fill: "none",
              stroke: "#153862",
            },
            "&.globe-icon": {
              fill: "#153862",
              stroke: "none",
            },
            "&.auto-renew-icon, &.subscription-grey-icon, &.chevron-right-icon": {
              fill: "none",
              stroke: "none",
            },
            "&.info-icon": {
              width: "33px",
              height: "28px",
              fill: "none",
              stroke: "#153862",
            },
          },
          "input:-webkit-autofill, input:-internal-autofill-selected": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            transition: "background-color 5000s ease-in-out 0s !important",
          },
          "&.order-history": {
            "& .order-history-header": {
              display: "flex",
              justifyContent: "space-between",
              border: "2px solid #99BCDF",
              borderRadius: "12px 12px 0  0",
              padding: "5px 16px",
            },
            "& .order-history-items": {
              border: "1px solid #99BCDF",
              borderRadius: "0  0 12px 12px",
              borderTop: "none",
              padding: "16px",
              "& .order-history-items-list": {
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: "20px",
                "& .order-history-item": {
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "space-between",
                  paddingTop: "20px",
                  alignItems: "center",
                  "& .order-history-item-image": {
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    width: "fit-content",
                    "& img": {
                      width: "40px",
                      objectFit: "contain",
                    }
                  },
                  "& .order-history-item-details": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                  }
                },
              },
              "& hr": {
                borderColor: "#99BCDF",
              },
              "& .order-history-item-footer": {
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "space-between",
                paddingTop: "16px",
              }
            }
          },
          "& .order-details-screen": {
            "& .order-details-screen-content": {
              borderRadius: "12px 12px 0  0",
              borderColor: "#99BCDF",
            },
            "& .order-details-screen-body": {
              borderRadius: "0  0 12px 12px",
              borderTop: "none",
              borderColor: "#99BCDF",
            },
            "& .order-details-screen-header": {
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            },
            "& .order-history-items-list": {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingBottom: "20px",
              "& .order-history-item": {
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "space-between",
                paddingTop: "20px",
                "& .order-history-item-image": {
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "space-between",
                  width: "fit-content",
                  "& img": {
                    width: "40px",
                    objectFit: "contain",
                  }
                },
                "& .order-history-item-details": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "space-between",
                }
              },
            },
          },
          "& .dashboard-focus-mode-container": {
            padding: "20px 32px",
            "& .dashboard-focus-mode-title": {
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            },
            "& .dashboard-focus-mode-chip": {
              backgroundColor: "#153862",
              color: "#fff",
              marginLeft: "5px",
              height: "16px",
              fontSize: "10px",
              fontWeight: "700",
              padding: 0,
              '& .MuiChip-label': {
                padding: '0px 5px',
                lineHeight: 1
              }
            },
            "& .dashboard-focus-mode-details": {
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "17px",
            }
          },
          "& .focus-mode-learn-more": {
            color: "#153862",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "600",
            marginLeft: "4px"
          },
          "& .custom-link-section-focus-mode-details": {
            fontSize: "11.3px !important",
            fontWeight: "400",
            lineHeight: "17px",
          },
          "& .custom-link-section-focus-mode-learn-more": {
            color: "#153862",
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "600",
            marginLeft: "4px"
          }
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.u-chip": {
              display: "flex",
              alignItems: "center",
              gap: '2px',
              borderRadius: '38px',
              whiteSpace: 'nowrap',
              height: "20px",
              width: "fit-content",
              userSelect: "none",
              "& .MuiChip-label": {
                padding: "1px 10px",
                display: "flex",
              },
              "& .MuiTypography-root": {
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                fontFamily: "Inter",
                whiteSpace: 'nowrap',
              },
              "& .MuiChip-avatar": {
                marginLeft: "1px",
                marginRight: "-6px",
                width: "auto",
                height: "20px",
                fontSize: "10px",
                fontWeight: "600",
                whiteSpace: 'nowrap',
                color: "#000",
                backgroundColor: "rgba(1, 1, 1, 0.1)",
              },
              "& .MuiChip-label .MuiTypography-root": {
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "600"
              },
              "& .MuiChip-icon": {
                marginLeft: "1px",
                "&.auto-renew-icon": {
                  filter: "brightness(150%) saturate(0) contrast(100%)",
                  opacity: "1"
                },
                "&.subscription-grey-icon": {
                  filter: "brightness(150%) saturate(0) contrast(100%)",
                  opacity: "0.5"
                }
              },
              "& .MuiChip-deleteIcon": {
                marginRight: "1px",
              },
              "&.large": {
                padding: "5px 10px",
                height: "30px",
                "& .MuiChip-label .MuiTypography-root": {
                  fontSize: "12px",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }
              }
            },
          }
        },
        variants: [
          {
            props: { className: 'u-chip', variant: 'leg1-holder' },
            style: {
              background: "rgba(67, 192, 111, 0.16)",
              color: "#43C06F",
            },
          },
          {
            props: { className: 'u-chip', variant: 'leg2-holder' },
            style: {
              background: "rgba(0, 158, 207, 0.16)",
              color: "#009ECF",
            },
          },
          {
            props: { className: 'u-chip', variant: 'member' },
            style: {
              backgroundColor: "#EDEFF1",
              color: "#153862",
            },
          },
          {
            props: { className: 'u-chip', variant: 'suspended' },
            style: {
              backgroundColor: "#FFEEEE",
              color: "#DF4747",
            },
          },
          {
            props: { className: 'u-chip', variant: 'inactive' },
            style: {
              backgroundColor: "#EDEFF1",
              color: "#153862",
            },
          },
          {
            props: { className: 'u-chip', variant: 'avatar' },
            style: {
              backgroundColor: "#EDEFF1",
              color: "#060504",
              fontSize: "10px",
              fontWeight: "600",
              height: "30px !important",
              "& .MuiAvatar-root": {
                marginLeft: "5px",
                marginRight: "-6px",
                width: "24px",
                height: "24px",
                fontSize: "0.75rem",
              }
            },
          },
          /** < statusTable */
          {
            props: { className: 'u-chip', variant: 'Fulfilled' },
            style: {
              backgroundColor: "rgba(0, 100, 55, 0.11)",
              color: "#006436",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Cancelled' },
            style: {
              backgroundColor: "rgba(100, 0, 55, 0.11)",
              color: "#640033",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'ReadyToProcess' },
            style: {
              backgroundColor: "rgba(200, 138, 2, 0.11)",
              color: "#C88A02",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Processing' },
            style: {
              backgroundColor: "rgba(200, 138, 2, 0.11)",
              color: "#C88A02",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Unfulfilled' },
            style: {
              backgroundColor: "rgba(100, 0, 55, 0.11)",
              color: "#640033",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Hold' },
            style: {
              backgroundColor: "rgba(0, 55, 100, 0.11)",
              color: "#003764",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Delivered' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.DELIVERED.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.DELIVERED.COLOR,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Backordered' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.BACKORDERED.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.BACKORDERED.COLOR,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Returned' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.RETURNED.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.RETURNED.COLOR,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Canceled' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.CANCELED.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.CANCELED.COLOR,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          /** statusTable > */
          /** < autoship status */
          {
            props: { className: 'u-chip', variant: 'subscribed' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.SUBSCRIBED.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.SUBSCRIBED.COLOR,
              height: "20px",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'one_time_purchase' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.ONE_TIME_PURCHASE.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.ONE_TIME_PURCHASE.COLOR,
              height: "20px",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          /** / autoship status > */
          /** < rank chips */
          {
            props: { className: 'u-chip', variant: 'rank-1' },
            style: {
              backgroundColor: THEME_COLORS.LIGHT.CHIP.RANK_1.BACKGROUND,
              color: THEME_COLORS.LIGHT.CHIP.RANK_1.COLOR,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-2' },
            style: {
              backgroundColor: 'rgba(237, 239, 241, 1)',
              color: "rgba(145, 158, 171, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-3' },
            style: {
              backgroundColor: 'rgba(255, 173, 145, 0.15)',
              color: "rgba(255, 155, 121, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-4' },
            style: {
              backgroundColor: 'rgba(255, 173, 145, 0.15)',
              color: "rgba(255, 155, 121, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-5' },
            style: {
              backgroundColor: 'rgba(255, 173, 145, 0.15)',
              color: "rgba(255, 155, 121, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-6' },
            style: {
              backgroundColor: 'rgba(67, 192, 111, 0.15)',
              color: "rgba(67, 192, 111, 1)",
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-7' },
            style: {
              backgroundColor: 'rgba(67, 192, 111, 0.15)',
              color: "rgba(67, 192, 111, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-8' },
            style: {
              backgroundColor: 'rgba(67, 192, 111, 0.15)',
              color: "rgba(67, 192, 111, 1)",
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-9' },
            style: {
              backgroundColor: "rgba(0, 158, 207, 0.15)",
              color: "rgba(0, 158, 207, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-10' },
            style: {
              backgroundColor: "rgba(0, 158, 207, 0.15)",
              color: "rgba(0, 158, 207, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-11' },
            style: {
              backgroundColor: "rgba(0, 158, 207, 0.15)",
              color: "rgba(0, 158, 207, 1)"
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-12' },
            style: {
              backgroundColor: "rgba(186, 113, 174, 0.15)",
              color: "rgba(186, 113, 174, 1)"
            },
          },
          /** / rank chips > */
          /** < customer type chip */
          {
            props: { className: 'u-chip', variant: 'type-member' },
            style: {
              backgroundColor: "rgba(90, 143, 195, 0.10)",
              color: "#5A8FC3",
            },
          },
          {
            props: { className: 'u-chip', variant: 'type-retail' },
            style: {
              backgroundColor: "rgba(90, 143, 195, 0.10)",
              color: "#5A8FC3",
            },
          },
          {
            props: { className: 'u-chip', variant: 'type-wholesale' },
            style: {
              backgroundColor: "rgba(90, 143, 195, 0.10)",
              color: "#5A8FC3",
            },
          },
          {
            props: { className: 'u-chip', variant: 'type-preferred' },
            style: {
              backgroundColor: "rgba(90, 143, 195, 0.10)",
              color: "#5A8FC3",
            },
          },
          /** / customer type chip > */
          /** < subscription chip */
          {
            props: { className: 'u-chip', variant: 'subscription-chip-subscribed' },
            style: {
              backgroundColor: "#E6F5FA",
              color: "#009ECF",
            }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-canceled' },
            style: {
              backgroundColor: "#FFF3EF",
              color: "#FF9B79",
            }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-default' },
            style: {
              backgroundColor: "#DDE1E5",
              color: "#84909C",
            }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-one-time-purchase' },
            style: {
              backgroundColor: "#ECF9F1",
              color: "#43C06F",
            }
          },
          /** / subscription chip > */
          /** < sponsor enroller chip */
          {
            props: { className: 'u-chip large', variant: 'sponsor-enroller-chip' },
            style: {
              color: "rgba(145, 114, 209, 1)",
              backgroundColor: "rgba(145, 115, 209, 0.102)",
            }
          },
          /** / sponsor enroller chip > */
        ],
      },
      MuiModal: {
        styleOverrides: {
          root: {
            "& .common-modal": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              height: "fit-content",
              position: "absolute",
              width: 400,
              boxShadow: 5,
              padding: "2rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "8px",
            }
          }
        }
      },
      MuiStack: {
        styleOverrides: {
          root: {
            "&.notice-stack": {
              borderRadius: "8px",
              border: "1px solid rgba(0, 0, 0, 0.05)",
              backgroundColor: "rgba(0, 0, 0, 0.25)",
              "& img[src$='.svg']": {
                opacity: "1"
              },
            },
            '&.login-form': {
              boxSizing: 'content-box',
              paddingTop: '3em',
              paddingBottom: '3em',
              flexDirection: 'column',
              gap: 0,
              width: '90vw',
              '@media (min-width: 0px)': {
                gap: '24px',
                paddingLeft: '5em',
                paddingRight: '5em',
              },
              '@media (min-width: 600px)': {
                gap: '32px',
                paddingLeft: '5em',
                paddingRight: '5em',
                width: '360px',
              },
              '@media (min-width: 900px)': {
                gap: '40px',
              },
              '& .login-form-buttons': {
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '16px',
                '@media (min-width: 600px)': {
                  flexDirection: 'column',
                  gap: '16px',
                },
                '@media (min-width: 900px)': {
                  flexDirection: 'row',
                  gap: '32px',
                },
              },
            },
            '&.referral-link-box': {
              width: "auto",
              height: "auto",
              borderWidth: "1.9px",
              borderStyle: "dashed",
              marginTop: '25px',
              borderRadius: '8px',
            },
            '&.referral-link-box-focus-mode': {
              backgroundColor: '#e9f1fa',
              borderColor: '#a4d1dd',
            },
            '&.referral-link-box-default': {
              backgroundColor: '#E6F5FA',
              borderColor: '#8AD2E9',
            },
            "&.focus_link_box": {
              minWidth: "280px",
              minHeight: "112px",
              backgroundColor: '#E9F1FA',
              borderColor: '#009ECF',
              borderRadius: '8px',
              padding: '8px 12px'
            },
            "&.language-modal-desktop": {
              backgroundColor: "rgba(255, 255, 255, 1)",
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid rgba(145, 158, 171, 0.10)',
              boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
              borderRadius: "8px",
              padding: "20px"
            },
            "&.article-card": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              border: "1px solid rgba(145, 158, 171, 0.10)",
              boxShadow: "0px 1px 3px 0px rgba(145, 158, 171, 0.10)",
            }
          }
        },
        variants: [
          {
            props: { variant: 'error' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(255, 0, 0, 0.25)",
                backgroundColor: "rgba(255, 0, 0, 0.05)",
              },
            },
          },
          {
            props: { variant: 'warning' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(241, 182, 68, 0.25)",
                backgroundColor: "rgba(241, 182, 68, 0.05)",
              },
            },
          },
          {
            props: { variant: 'info' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(0, 158, 207, 0.15)",
                backgroundColor: "rgba(0, 158, 207, 0.05)",
              },
            },
          },
          {
            props: { variant: 'success' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(0, 128, 0, 0.25)",
                backgroundColor: "rgba(0, 128, 0, 0.05)",
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "7px",
            fontWeight: "600",
            height: "45px",
            padding: "0px 1px 0px 1px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            "&.u-button": {
              color: "#153862",
              display: "flex",
              alignItems: "center",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: "600",
              padding: "5px 12px 5px 12px",
              background: " #ffffff",
              border: "1px solid rgba(145, 158, 171, 0.2)",
              borderRadius: "8px",
              "&:hover": {
                cursor: "pointer"
              },
              "&[aria-disabled='true']": {
                color: "#919EAB",
                background: "#F1F4F7",
                "&:hover": {
                  cursor: "not-allowed"
                }
              }
            },
            "&.language-switcher": {
              width: 148,
              justifyContent: "space-between",
              border: "1px solid rgba(145, 158, 171, 0.1);",
              boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
              paddingRight: "10px",
              borderRadius: '0',
              fontSize: 12,
              fontWeight: "500",
              paddingLeft: "10px",
              color: "#153862",
              '@media (max-width: 600px)': {
                borderRadius: '12px',
              },
            },
            '&.referral-link-copy-button': {
              color: "#fff",
              backgroundColor: "#153862",
              borderRadius: "8px",
              border: "1px solid rgba(145, 158, 171, 0.20)",
              padding: "10px",
              marginTop: "20px",
              textDecoration: 'none'
            },
            '&.referral-link-copy-button-focus-mode': {
              backgroundColor: "#153862",
              border: "1px solid rgba(145, 158, 171, 0.20)",
              "&:hover": {
                backgroundColor: "#10324D",
                opacity: ".7"
              }
            },
            '&.referral-link-copy-button-default': {
              backgroundColor: "#009ECF",
              border: "1px solid rgba(145, 158, 171, 0.20)",
              "&:hover": {
                backgroundColor: "#0089B3F",
                opacity: ".7"
              }
            }
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            border: "none",
            fontFamily: '"Inter", sans-serif',
            color: "primary.main",
            "& .MuiDataGrid-columnHeaders": {
              background: "none"
            },
            "& .MuiDataGrid-columnHeader": {
              background: "none"
            }
          },
          cell: {
            borderBottom: `1px solid ${THEME_COLORS.LIGHT.BORDER.DATAGRID}`,
            ":focus-within": {
              outline: "none",
            },
          },
          columnHeaders: {
            borderBottomColor: THEME_COLORS.LIGHT.BORDER.DATAGRID,
          },
          columnHeader: {
            border: "none",
            backgroundColor: THEME_COLORS.LIGHT.BACKGROUND_DEFAULT,
            letterSpacing: "-0.01em",
            ":focus-within": {
              outline: "none",
            },
          },
          "columnHeader--sorted": {
            textDecoration: "underline",
          },
          columnHeaderTitle: {
            fontWeight: 600,
          },
          columnSeparator: {
            display: "none",
          },
          footerContainer: {
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "stretch",
            justifyContent: "flex-start",
            borderTop: "1px solid rgba(237, 239, 241, 1)"
          },
          row: {
            ":hover": {
              backgroundColor: THEME_COLORS.LIGHT.HOVER.DATAGRID_ROW,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: THEME_COLORS.LIGHT.TEXT_PRIMARY,
            "&:hover": {
              color: THEME_COLORS.LIGHT.TEXT_PRIMARY,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: THEME_COLORS.LIGHT.TEXT_PRIMARY,
            textDecorationColor: THEME_COLORS.LIGHT.TEXT_PRIMARY,
            ":hover:not(.resource-nav-item-link)": {
              color: THEME_COLORS.LIGHT.HOVER.LINK,
            },
            fontFamily: '"Inter", sans-serif',
            "&.com-button": {
              color: '#153862',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: '600',
              padding: '8px',
              background: '#ffffff',
              border: '1px solid rgba(145, 158, 171, 0.20)',
              boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10)',
              borderRadius: '8px',
              userSelect: 'none',
              "& svg": {
                opacity: "1",
                fill: "#153862",
              },
              '&:hover': {
                cursor: 'pointer',
                '&[aria-disabled="true"]': {
                  cursor: 'not-allowed',
                },
              },
              '&[aria-disabled="true"]': {
                color: '#919EAB',
                background: '#F1F4F7',
                opacity: '.5',
                border: '1px solid rgba(145, 158, 171, 0.20)',
                boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10)',
                cursor: 'not-allowed',
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#153862",
            opacity: "0.5",
            "&.Mui-focused": {
              opacity: "1",
            },
          },
          shrink: {
            fontFamily: "Inter",
            fontSize: "14px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderRadius: "8px",
              border: `1px solid ${THEME_COLORS.LIGHT.BORDER.TEXT_FIELD}`,
            },
            "&:hover:not(:focus):not(:focus-within) fieldset": {
              border: `1px solid ${THEME_COLORS.LIGHT.BORDER.TEXT_FIELD} !important`,
            },
          },
        },
      },
      // Style dates on calendar
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            "&:focus.Mui-selected": {
              backgroundColor: "rgb(4, 158, 207)",
            },
          },
        },
      },
      // style calendar container
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            padding: "24px 24px 24px 10px",
            color: "rgb(21, 56, 98)",
          },
        },
      },
      // style calendar outer container
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.MuiPickersPopper-paper": {
              boxShadow: "0px 6px 12px rgba(145, 158, 171, 0.2)",
            },
          },
        },
      },
      // style calendar header
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            fontFamily: "Inter",
          },
        },
      },
      // style M T W etc. in calendar
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.MuiDayPicker-weekDayLabel": {
              fontFamily: "Inter",
            },
            "&.referral-link-switch-link": {
              fontWeight: "400",
              fontSize: "11px",
              textAlign: "center",
              lineHeight: "13.2px",
              textDecoration: "underline",
              cursor: "pointer",
            },
            "&.referral-link-switch-link-focus-mode": {
              color: "#163861",
            },
            "&.referral-link-switch-link-default": {
              color: "#2b3c69",
            }
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            '&.subheader': {
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 10,
              color: "#8A9BB0",
              marginTop: "8px",
              marginBottom: "8px",
              marginLeft: "24px"
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            borderRadius: "8px",
            marginTop: "5px",
            fontWeight: "500",
            fontSize: "12px",
            '&.Mui-selected': {
              color: "rgba(0, 158, 207, 1)",
              backgroundColor: "rgba(0, 158, 207, 0.05)",
              fontWeight: "600",
              "&.MuiInputBase-inputSizeSmall": {
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "11px",
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
              }
            },
            '&.MuiMenuItem-root': {
              display: "flex",
              flexDirection: "row",
              minWidth: "230px",
              margin: 0,
              borderRadius: 0,
              border: "none",
              boxShadow: "none",
              justifyContent: "start",
            },
            "& img[src$='.svg']": {
              opacity: "0.8"
            },
          },
        },
      },
      // style number value inside of pagination select tag
      MuiSelect: {
        styleOverrides: {
          root: {
            fontWeight: "500",
            fontSize: "14px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: 'inherit',
            },
            "&.MuiInputBase-inputSizeSmall": {
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "11px",
              paddingTop: "5.5px",
              paddingBottom: "5.5px",
            },
            '& .MuiInputBase-input': {
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 16px 8px 16px",
            }
          },
        },
      },
      // style height for pagination select tag
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.MuiInputBase-sizeSmall": {
              height: "24px",
              borderRadius: "4px",
            },
          },
        },
      },
      // style chevron on pagination select tag
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "rgb(21, 56, 98)",
            fill: "rgb(21, 56, 98)",
            "&.MuiSelect-icon": {
              fontSize: "1.2rem",
            },
          },
        },
      },
      // style disabled but checked option in SortByDropdownMobile >> Manage columns
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.MuiButtonBase-root": {
              marginBottom: "0",
            },
            // override disabled & hovered styling
            "&.MuiIconButton-root:hover": {
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)"
              }
            },
            "&.Mui-selected": {
              backgroundColor: "rgba(0, 158, 207, 0.08)",

              "& img[src$='.svg']": {
                opacity: "0.8"
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 158, 207, 0.1)',
              }
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 158, 207, 0.1)',
            }
          },
        },
      },
      // center alert message
      MuiAlert: {
        styleOverrides: {
          message: {
            color: "#primary.main",
            display: "flex",
            verticalAlign: "middle",
            padding: "10px"
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 8,
            '& .MuiSwitch-track': {
              backgroundColor: "#DDDDDD",
              borderRadius: 22 / 2,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              '&::before': {
                left: 12,
              },
              '&::after': {
                right: 12,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: 16,
              height: 16,
              margin: 2,
              backgroundColor: "#153862",
            },
            '&.focus_mode_toggle': {
              '& .MuiSwitch-switchBase': {
                '&.Mui-checked': {
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#75797f',
                    opacity: 1,
                  },
                  '& .MuiSwitch-thumb': {
                    backgroundColor: '#FFFFFF',
                  },
                },
              },
              '& .MuiSwitch-track': {
                backgroundColor: '#555555',
                opacity: 0.5,
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: '#DDDDDD',
              },
            }
          }
        }
      }
    },
    subscription: {
      subscribed: {
        color: "#009ECF",
        backgroundColor: "#E6F5FA"
      },
      default: {
        color: "#84909C",
        backgroundColor: "#DDE1E5"
      },
      oneTimePurchase: {
        color: "#43C06F",
        backgroundColor: "#ECF9F1"
      },
    }
  });

export default createDefaultTheme;
